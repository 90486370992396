import { NavLink, useRouteMatch } from 'react-router-dom';
import classes from './ZoneList.module.css';

const ZoneList = (props) => {
  const match = useRouteMatch();
  const { selectedStadiumId, zoneList, onAsideOpen } = props;
  const url = match.path.replace(':stadiumId', selectedStadiumId);

  return (
    <ul className={classes.ZoneList}>
      {zoneList.map((zone) => (
        <li key={zone.id} className={classes['ZoneList__Item']}>
          <NavLink
            to={url.replace(':zoneId', zone.id)}
            className={classes['ZoneList__Link']}
            activeClassName={classes.active}
            onClick={() => onAsideOpen(false)}
          >
            {zone.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default ZoneList;
