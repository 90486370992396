import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import StadiumSelect from './StadiumSelect/StadiumSelect';
import ZoneList from './ZoneList/ZoneList';
import UtilityMenu from './UtilityMenu/UtilityMenu';
import AuthContext from '../../store/auth-context';
import classes from './GlobalNav.module.css';

const GlobalNav = (props) => {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const [selectedStadiumId, setSelectedStadiumId] = useState(+params.stadiumId);
  const changeSelectedStadiumHandler = (stadiumId) => {
    setSelectedStadiumId(+stadiumId);
  };
  const zoneList = authCtx.stadiumsList.find((stadium) => {
    return stadium.id === selectedStadiumId;
  }).zones;

  return (
    <nav className={classes.GlobalNav}>
      <header className={classes['GlobalNav__Header']}>
        <div className={classes['GlobalNav__HeaderInner']}>
          <StadiumSelect
            selectedStadiumId={selectedStadiumId}
            stadiums={authCtx.stadiumsList}
            onChangeSelectedStadium={changeSelectedStadiumHandler}
          />
        </div>
      </header>
      <div className={classes['GlobalNav__Body']}>
        <div className={classes['GlobalNav__BodyInner']}>
          <ZoneList
            {...props}
            selectedStadiumId={selectedStadiumId}
            zoneList={zoneList}
          />
        </div>
      </div>
      <div className={classes['GlobalNav__Footer']}>
        <a
          href='https://airtable.com/appChvyG5VDCftKp4/shrKIr7valcpjfzB5'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          공실 알리기 서식 작성하기
        </a>
        <a
          href='https://airtable.com/appJMFXvomJqnnxlX/shrkxf9EFoszdA2hX'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          일정 변경 서식 작성하기
        </a>
        <a
          href='https://airtable.com/appJMFXvomJqnnxlX/shrfZ1FrtE9DnZOyx'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          정기 일정 변경 서식 작성하기
        </a>
        <a
          href='https://plabfootball.notion.site/5a9452dac5634622ba3f992e0defa078'
          target='_blank'
          rel='noopener noreferrer'
          className={classes['LinkGuide']}
        >
          플랩 구장관리 사이트 이용 가이드 보기
        </a>
        <div className={classes['GlobalNav__FooterInner']}>
          <UtilityMenu />
        </div>
      </div>
    </nav>
  );
};

export default GlobalNav;
