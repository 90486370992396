import { Fragment, useContext, useRef } from 'react';

import AuthContext from '../../../store/auth-context';
import ChevronLeft from '../../UI/Icons/Chevron/ChevronLeft';
import classes from './StadiumSelect.module.css';

const StadiumSelect = (props) => {
  const { selectedStadiumId, stadiums, onChangeSelectedStadium } = props;
  const authCtx = useContext(AuthContext);
  const stadiumInputRef = useRef();

  const submitHandler = (evt) => {
    evt.preventDefault();

    const selectedStadium = stadiums.find(
      (stadium) => stadium.name === stadiumInputRef.current.value
    );

    if (selectedStadium) {
      onChangeSelectedStadium(selectedStadium.id);
    }
    stadiumInputRef.current.value = '';
  };

  return (
    <Fragment>
      {authCtx.userRole === '마스터' && (
        <form onSubmit={submitHandler} className={classes.SearchForm}>
          <input
            ref={stadiumInputRef}
            type='text'
            list='stadiums-list'
            placeholder='구장명 입력'
            className={classes['SearchForm__Input']}
          />
          <datalist id='stadiums-list'>
            {stadiums.map((stadium) => (
              <option key={stadium.id} value={stadium.name} />
            ))}
          </datalist>
          <button type='submit' className={classes['SearchForm__SubmitBtn']}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#535353'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-search'
            >
              <circle cx='11' cy='11' r='8'></circle>
              <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
            </svg>
          </button>
        </form>
      )}
      <div className={classes.StadiumSelect}>
        <select
          value={selectedStadiumId}
          onChange={(evt) => onChangeSelectedStadium(evt.target.value)}
          className={classes['StadiumSelect__Select']}
        >
          {stadiums.map((stadium) => (
            <option key={stadium.id} value={stadium.id}>
              {stadium.name}
            </option>
          ))}
        </select>
        <i className={classes['StadiumSelect__Icon']}>
          <ChevronLeft />
        </i>
      </div>
    </Fragment>
  );
};

export default StadiumSelect;
