import Scheduler from '../../components/Scheduler/Scheduler';
import DateNavigator from '../../components/Scheduler/DateNavigator/DateNavigator';
import MonthView from '../../components/Scheduler/MonthView/MonthView';
import WeekView from '../../components/Scheduler/WeekView/WeekView';
import CommonLayout from '../../layout/CommonLayout';

const Schedule = () => {
  return (
    <CommonLayout>
      <Scheduler>
        <DateNavigator />
        <MonthView />
        <WeekView startDayHour={6} endDayHour={30} />
      </Scheduler>
    </CommonLayout>
  );
};

export default Schedule;
