import CommonLayout from '../../layout/CommonLayout';

const Settlements = (props) => {
  return (
    <CommonLayout>
      <div>구장 정산</div>
    </CommonLayout>
  );
};

export default Settlements;
