import { useState, useContext } from 'react';

import NewPwdForm from '../../../Forms/NewPwdForm/NewPwdForm';
import AuthContext from '../../../../store/auth-context';
import classes from './UserSetting.module.css';

const UserSetting = (props) => {
  const authCtx = useContext(AuthContext);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const newPwdFormOpenHandler = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  return (
    <div className={classes.UserSetting}>
      <div className={classes['UserSetting__EmailId']}>
        {authCtx.userEmailId}
      </div>
      <button
        type='button'
        className={classes['UserSetting__Change-PWD']}
        onClick={newPwdFormOpenHandler}
      >
        비밀번호 변경
      </button>
      {isFormOpen && <NewPwdForm onClose={newPwdFormOpenHandler} />}
    </div>
  );
};

export default UserSetting;
