import { useContext, useState, useCallback } from 'react';
import AuthContext from '../store/auth-context';

const useHttp = () => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (requestConfig, returnResObj) => {
      setIsLoading(true);

      const response = await fetch(
        `https://api-dev.plab.so/${requestConfig.urlPath}`,

        {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers
            ? requestConfig.headers
            : {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
              },
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        }
      );

      setIsLoading(false);
      returnResObj(response);
    },
    [authCtx.token]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
