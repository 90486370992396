import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import classes from './HeaderTitle.module.css';

const HeaderTitle = () => {
  const authCtx = useContext(AuthContext);
  const params = useParams();

  const stadium = authCtx.stadiumsList.find(
    (stadium) => stadium.id === +params.stadiumId
  );
  const zone = stadium.zones.find((zone) => zone.id === +params.zoneId);

  return (
    <div className={classes.HeaderTitle}>
      <h2 className={classes['HeaderTitle__Text']}>
        {`${stadium.name} ${zone.name}`}
      </h2>
    </div>
  );
};

export default HeaderTitle;
